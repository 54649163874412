import React from "react"
import { HowCard } from "./how-card"
import after from "../images/after.png"
import before from "../images/before.png"
import ReactCompareImage from "react-compare-image"

export const SteelunicornHow = () => {
  return (
    <div className="steelunicorn-how steelunicorn-page">
      <div className="steelunicorn-how-container">
        <div className="steelunicorn-how-title steelunicorn-title">
          <h1>How it works?</h1>
          <ReactCompareImage
            leftImage={before}
            rightImage={after}
          />
          <p>The automated system creates drawings for reinforced concrete buildings.</p>
        </div>
        <div className="steelunicorn-how-title steelunicorn-title">
          <h1>Manual vs. SteelUnicorn workflow</h1>
          <p>The work on Formwork Design was done <b>75%</b> faster with the SteelUnicorn plugin.</p>
          <iframe width="1280" height="460" src="https://www.youtube.com/embed/25CGxu6GWRo" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
          <p>The work on Formwork Design was done <b>93%</b> faster with the SteelUnicorn plugin.</p>
          <iframe width="1280" height="456" src="https://www.youtube.com/embed/XLEvI_SfXds" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
        </div>
        <div className="steelunicorn-how-card-containter">
          <HowCard title="SPEED" description="Speed up the project by at least 10%"/>
          <HowCard title="ACCUARACY" description="Reduce the use of structural engineers by more than 86%"/>
          <HowCard title="ECONOMY" description="Free up at least 8% of the project budget"/>
          <HowCard title="AI AND ML"
                   description="Automation of the process: requires input data and brief verification of the result"/>
        </div>
      </div>
    </div>
  )
}
import React from "react"

export const TeamCard = (props) => {
  return (
    <div className="team-card">
      {<img src={props.src} alt={props.alt}/>}
      {<h4>{props.name}</h4>}
      {<p className="team-card-role">{props.role}</p>}
      <ul>
        {props.abilities.map((ability) => <li>{ability}</li>)}
      </ul>
    </div>
  )
}
import React from "react"

export const SteelunicornHeader = () => {
  return (
    <div className="steelunicorn-header steelunicorn-page">
      <div className="steelunicorn-header-title">
        <h1>STEELUNICORN</h1>
        <p>Structural design. Reinvented.</p>
      </div>
    </div>
  )
}
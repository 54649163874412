import React from "react"
import { Card } from "@material-ui/core"
import CardContent from "@material-ui/core/CardContent"

export const ProblemCard = (props) => {
  return (
    <Card className="problem-card">
      <CardContent>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </CardContent>
    </Card>)
}